import React, { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  CUSTOMER_TYPE,
  POLICY_STATUS,
  POLICY_TYPE,
  caseStatus,
  insurers,
  kycErrorMesssage,
  moveToTop,
  reasonForNotOptingCpa,
  toastFailure,
} from "../utility/constants";
import ReactSelect from "../Tags/ReactSelectProposal";
import moment from "moment";
import {
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
} from "../../store/action/userActions";
import { processFlow, submitProposal } from "../services/TPApiCall";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PremiumMismatchModal from "./PremiumMismatchModal";
import { debounce } from "lodash";
import { FixedSizeList as List } from "react-window";
import DatePicker from "react-datepicker";
import ReactInputMask from "react-input-mask";
import { getVehiclePreviousInsurer } from "../services/masterServices";

const VehicleDetails = ({
  insurerData,
  cityData,
  financierData,
  currentStep,
  setCurrentStep,
  kycMode,
  setKycMode,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    getValue,
    watch,
    clearErrors,
  } = useForm();

  const motorRequest = useSelector((state) => state.root.motorRequest);
  const userDetails = useSelector((state) => state.root.userDetails);
  const [vehicleDetailsSpinner, setVehicleDetailsSpinner] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [premiumMismatchModal, setPremiumMismatchModal] = useState(false);
  const [submitProposalError, setSubmitProposalError] = useState({});
  const [updatedQuote, setUpdatedQuote] = useState({});
  const [Hpn, setHpn] = useState(false);
  const [isValidPUC, setIsValidPUC] = useState(motorRequest?.isValidPuc);
  const [userInputBank, setUserInputBank] = useState([]);
  const [userInputCity, setUserInputCity] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [citySuggestions, setCitySuggestions] = useState([]);

  const [selectedPACheckbox, setSelectedPACheckbox] = useState(null);
  const [prevTPPolicyEndDate, setPrevTPPolicyEndDate] = useState(
    motorRequest?.prevTPPolicyEndDate ? motorRequest?.prevTPPolicyEndDate : null
  );

  const selectedPlan = useSelector((state) => state?.root?.selectedPlan);
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );

  const [prevPAPolicyEndDate, setPrevPAPolicyEndDate] = useState(
    motorRequest?.prevPAPolicyEndDate
      ? new Date(motorRequest.prevPAPolicyEndDate)
      : null
  );

  const [pucValidUpto, setPucValidUpto] = useState(
    motorRequest?.pucValidUpto || ""
  );
  const navigate = useNavigate();
  const bankWrapperRef = useRef(null);
  const cityWrapperRef = useRef(null);

  useEffect(() => {
    if (!Hpn) {
      setUserInputBank([]);
      setSuggestions([]);
      setCitySuggestions([]);
      setValue("hpnBank", "");
      setValue("hpnCity", "");
      dispatchMotorQuote("hpnBank", "");
      dispatchMotorQuote("hpnCity", "");
    }
  }, [Hpn]);

  useEffect(() => {
    setValue("prevTPPolicyEndDate", prevTPPolicyEndDate);
  }, [prevTPPolicyEndDate]);

  useEffect(() => {
    if (!isValidPUC) {
      dispatchMotorQuote("pucNumber", "");
      dispatchMotorQuote("pucValidUpto", "");
      setValue("pucNumber", "");
      setValue("pucValidUpto", "");
    }
  }, [isValidPUC]);

  useEffect(() => {
    if (
      selectedPlan?.insurerId !== insurers.Future ||
      selectedPlan?.insurerId !== insurers.Magma
    ) {
      dispatchMotorQuote("pucNumber", "");
      dispatchMotorQuote("pucValidUpto", "");
    }

    if (motorRequest?.isHypothecation) {
      setHpn(true);
    }

    if (motorRequest?.isValidPuc) {
      setIsValidPUC(true);
      motorRequest?.pucValidUpto &&
        setValue("pucValidUpto", motorRequest?.pucValidUpto);
    }
    moveToTop();
  }, []);

  const submitVehicleDetails = async (data) => {
    console.log("dataaa", data);
    let newData = { ...motorRequest };

    if (
      motorRequest?.newPolicyType === POLICY_TYPE.ODONLY &&
      motorRequest?.prevPolicyType === POLICY_TYPE.PREVIOUSBUNDLED
    ) {
      dispatchMotorQuote("prevTpPolicyNumber", motorRequest?.prevPolicyNumber);
      dispatchMotorQuote("prevTpPolicyEndDate", data?.prevTPPolicyEndDate);
      dispatchMotorQuote("prevTpInsurerCode", data?.insurerCode);
      newData -
        {
          ...newData,
          prevTpPolicyNumber: motorRequest?.prevPolicyNumber,
          prevTpInsurerCode: data?.insurerCode,
          prevTpPolicyEndDate: data?.prevTPPolicyEndDate,
        };
    }

    console.log("submitVehicleDetails data", data);
    if (
      data?.engineNumber !==
        motorRequest?.savedDataForVahanDetails?.data?.engineNumber ||
      data?.chassisNumber !==
        motorRequest?.savedDataForVahanDetails?.data?.chassisNumber
    ) {
      dispatchMotorQuote("isVahanDetailsChanged", true);
      newData = { ...newData, isVahanDetailsChanged: true };
    }

    data = {
      ...data,
      ["registrationNumber"]: motorRequest?.registrationNumber,
    };
    console.log("submitVehicleDetails", data);
    setVehicleDetailsSpinner(true);
    try {
      let currentFlowData = {};
      for (let key in data) {
        if (key === "prevTPInsurerCode") {
          dispatchMotorQuote(`${key}`, parseInt(data[key]));
          dispatchProcessFlowMotor(`step10.${key}`, parseInt(data[key]));
          newData = { ...newData, [key]: data[key] };
          currentFlowData = { ...currentFlowData, [key]: parseInt(data[key]) };
        } else if (key === "reasonForNotOptingCpa") {
          if (parseInt(data[key]) === 1) {
            reset({
              prevPAInsurerCode: "",
              prevPAPolicyEndDate: "",
              prevPAPolicyNumber: "",
              prevPASumInsured: "",
            });

            for (let key in data) {
              if (key === "prevPAInsurerCode") {
                newData = { ...newData, [key]: "" };
                dispatchMotorQuote(`${key}`, data[key]);
              } else if (key === "prevPAPolicyEndDate") {
                newData = { ...newData, [key]: "" };
                dispatchMotorQuote(`${key}`, data[key]);
              } else if (key === "prevPAPolicyNumber") {
                newData = { ...newData, [key]: "" };
                dispatchMotorQuote(`${key}`, data[key]);
              } else if (key === "prevPASumInsured") {
                newData = { ...newData, [key]: "" };
                dispatchMotorQuote(`${key}`, data[key]);
              }
            }
          }
          dispatchMotorQuote(`${key}`, parseInt(data[key]));
          newData = { ...newData, [key]: parseInt(data[key]) };
          currentFlowData = { ...currentFlowData, [key]: parseInt(data[key]) };
        } else if (key === "registrationNumber") {
          newData = { ...newData, [key]: motorRequest?.registrationNumber };
        } else if (key === "engineNumber") {
          newData = { ...newData, [key]: data[key]?.toUpperCase() };
          dispatchMotorQuote(`${key}`, data[key]?.toUpperCase());
          currentFlowData = {
            ...currentFlowData,
            [key]: data[key]?.toUpperCase(),
          };
          console.log("newDAta", newData);
        } else if (key === "chassisNumber") {
          newData = { ...newData, [key]: data[key]?.toUpperCase() };
          dispatchMotorQuote(`${key}`, data[key]?.toUpperCase());
          currentFlowData = {
            ...currentFlowData,
            [key]: data[key]?.toUpperCase(),
          };
          console.log("newDAta", newData);
        } else if (key === "prevPAInsurerCode") {
          newData = { ...newData, [key]: parseInt(data[key]) };
          dispatchMotorQuote(`${key}`, parseInt(data[key]));
          currentFlowData = {
            ...currentFlowData,
            [key]: parseInt(data[key]),
          };
          console.log("newDAta", newData);
        } else if (key === "prevPASumInsured") {
          newData = { ...newData, [key]: parseInt(data[key]) };
          dispatchMotorQuote(`${key}`, parseInt(data[key]));
          currentFlowData = {
            ...currentFlowData,
            [key]: parseInt(data[key]),
          };
          console.log("newDAta", newData);
        } else {
          dispatchMotorQuote(`${key}`, data[key]);

          dispatchProcessFlowMotor(`step10.${key}`, data[key]);
          newData = { ...newData, [key]: data[key] };
          currentFlowData = { ...currentFlowData, [key]: data[key] };
        }
      }
      currentFlowData = {
        ...currentFlowData,
        hpnCity: motorRequest?.hpnCity,
        isHypothecation: motorRequest?.isHypothecation,
        isValidPuc: motorRequest?.isValidPuc,
      };

      if (selectedBank !== "") {
        newData["hpnBank"] = selectedBank;
      }

      // const dataForFlow = {
      //   ...processFlowObjMotor,
      //   customerLeadId: motorRequest?.customerLeadId,
      //   motorRequest: { ...motorRequest },
      //   processDiscription: "vehicleDetails",
      //   step: "step10",
      //   step10: {
      //     ...currentFlowData,
      //   },
      // };
      // console.log("dataForFlow", dataForFlow);
      // // processFlow(dataForFlow);
      console.log("NEW DATA PROPOSAL", newData);
      const response = await submitProposal(
        newData,
        selectedPlan,
        motorRequest?.kycFields,
        userDetails
      );
      if (response?.data?.caseStatus === caseStatus?.proposalSuccess) {
        dispatchMotorQuote("paymentUrl", response?.data?.paymentUrl);
        const dataForFlow = {
          ...processFlowObjMotor,
          customerLeadId: motorRequest?.customerLeadId,
          processDiscription: "vehicleDetails",
          step: "step10",
          step10: {
            vehicleDetails: {
              ...currentFlowData,
              proposalStatus: caseStatus.proposalSuccess,
              paymentUrl: response?.data?.paymentUrl,
            },
            motorRequest: { ...motorRequest },
            planDetails: selectedPlan,
          },
        };

        processFlow(dataForFlow);
        toast.success("Proposal Successful", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate(`/checkout/${motorRequest?.customerLeadId}`);
        setVehicleDetailsSpinner(false);
      } else if (
        response?.data?.caseStatus === caseStatus?.proposalPremiumMismatch
      ) {
        setPremiumMismatchModal(true);
        setSubmitProposalError(response?.data?.error);
        setUpdatedQuote(response?.data?.quote);
        setVehicleDetailsSpinner(false);
      } else if (
        response?.data?.errors?.some(
          (error) => error?.errorMessage === kycErrorMesssage.invalidKyc
        )
      ) {
        dispatchMotorQuote("form60Link", motorRequest?.panFileLink);
        dispatchMotorQuote("form60Id", motorRequest?.panFileName);
        setKycMode("ovd");
        dispatchMotorQuote("ovdActive", true);
        if (motorRequest?.frontId) {
          dispatchMotorQuote("frontId", "");
        }
        if (motorRequest?.backId) {
          dispatchMotorQuote("backId", "");
        }
        setCurrentStep(1);
        toastFailure("CKYC Failed. Please proceed with OVD");
        return;
      } else if (response?.data?.caseStatus === caseStatus?.proposalFailure) {
        setVehicleDetailsSpinner(false);
        toast.error(`${response?.data.errors[0].displayMessage}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        const dataForFlow = {
          ...processFlowObjMotor,
          customerLeadId: motorRequest?.customerLeadId,
          motorRequest: { ...motorRequest },
          processDiscription: "vehicleDetails",
          step: "step10",
          step10: {
            vehicleDetails: {
              ...currentFlowData,
              proposalStatus: response?.data.errors[0].displayMessage,
            },
            motorRequest: { ...motorRequest },
            planDetails: selectedPlan,
          },
        };
        processFlow(dataForFlow);
      } else {
        setVehicleDetailsSpinner(false);
        toast.error(`${response?.data.errors[0].displayMessage}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        const dataForFlow = {
          ...processFlowObjMotor,
          customerLeadId: motorRequest?.customerLeadId,
          motorRequest: { ...motorRequest },
          processDiscription: "vehicleDetails",
          step: "step10",
          step10: {
            vehicleDetails: {
              ...currentFlowData,
              proposalStatus: response?.data.errors[0].displayMessage,
            },
            motorRequest: { ...motorRequest },
            planDetails: selectedPlan,
          },
        };

        processFlow(dataForFlow);
      }
    } catch (error) {
      console.error("Error submitting vehicle details:", error);
      setVehicleDetailsSpinner(false);
    }
  };

  const handlePreviousStep = () => {
    if (selectedPlan?.PersonalAccident) {
      setCurrentStep(currentStep - 1);
    } else {
      setCurrentStep(currentStep - 2);
    }
    // setCurrentStep(currentStep - 1);
  };

  const changeHpnState = (e) => {
    const { checked, value } = e.target;

    dispatchQuickQuote("IsHypothecation", checked);
    dispatchMotorQuote("isHypothecation", checked);
    setHpn(checked);
  };

  const changePUCState = (e) => {
    const { checked, value } = e.target;

    dispatchQuickQuote("IsValidPuc", checked);
    dispatchMotorQuote("isValidPuc", checked);
    setIsValidPUC(checked);
  };
  useEffect(() => {
    if (motorRequest?.businessType === "new") {
      setIsValidPUC(false);
      dispatchQuickQuote("IsValidPuc", true);
      dispatchMotorQuote("isValidPuc", true);
    } else if (
      selectedPlan?.Api_name === insurers.Future.name ||
      selectedPlan?.Api_name === insurers.Magma.name
    ) {
      setIsValidPUC(true);
      dispatchQuickQuote("IsValidPuc", true);
      dispatchMotorQuote("isValidPuc", true);
    }
  }, [
    selectedPlan?.Api_name === insurers.Future.name ||
      selectedPlan?.Api_name === insurers.Magma.name,
    motorRequest?.businessType === "new",
  ]);

  const fetchBankSuggestions = (input) => {
    const filteredBankName = financierData.filter((name) =>
      name.value.toLowerCase().includes(input.toLowerCase())
    );
    setSuggestions(filteredBankName);
  };

  const getDistinctCities = (cities) => {
    const uniqueCities = [
      ...new Map(cities.map((item) => [item.City, item])).values(),
    ];
    return uniqueCities;
  };

  const fetchCitySuggestions = (input) => {
    console.log("cityData", cityData);
    const filteredCityName = cityData.filter((name) =>
      name.City.toLowerCase().includes(input.toLowerCase())
    );
    const distinctCities = getDistinctCities(filteredCityName);
    setCitySuggestions(distinctCities);
  };

  const debouncedFetchBankSuggestions = useMemo(
    () => debounce(fetchBankSuggestions, 300),
    [financierData]
  );
  const debouncedFetchCitySuggestions = useMemo(
    () => debounce(fetchCitySuggestions, 300),
    [cityData]
  );

  function capitalizeWords(value) {
    return value
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  // const handleInputBank = (event) => {
  //   const input = event.target.value;
  //   const capitalizedValue = capitalizeWords(input);

  //   setUserInputBank(capitalizedValue);
  //   debouncedFetchBankSuggestions(capitalizedValue);
  //   dispatchQuickQuote("HpnBank", capitalizedValue);
  // };

  const handleInputBank = (e) => {
    const inputValue = e.target.value;
    setUserInputBank(inputValue); // Set the input value

    // Fetch bank suggestions based on input
    if (inputValue.length > 0) {
      debouncedFetchBankSuggestions(inputValue); // Debounced function to fetch suggestions
    } else {
      setSuggestions([]); // Clear suggestions if input is empty
    }

    setSelectedBank(""); // Clear the selected bank until clicked
  };

  useEffect(() => {
    console.log("selectedBank", selectedBank);
    console.log("suggestions", suggestions);
  }, [selectedBank, suggestions]);

  const handleBankInputBlur = () => {
    // Check if the input bank exists in the suggestions
    const isBankInSuggestions = suggestions.some(
      (suggestion) => suggestion.value === userInputBank
    );

    if (!isBankInSuggestions) {
      setUserInputBank(""); // Clear input if not in suggestions
      setValue("hpnBank", ""); // Clear form value
    }
  };

  const handleBankSelection = (selectedBank) => {
    console.log("selectedBank", selectedBank);
    setUserInputBank(capitalizeWords(selectedBank?.value));

    dispatchQuickQuote("HpnBank", selectedBank?.value);
    dispatchMotorQuote("hpnBank", selectedBank?.value);
    dispatchMotorQuote("hpnBankData", selectedBank);
    setValue("hpnBank", selectedBank?.value);
    setSuggestions([]);
  };

  const handleCitySelection = (selectedCity) => {
    setUserInputCity(selectedCity);

    dispatchQuickQuote("HpnCity", selectedCity);
    dispatchMotorQuote("hpnCity", selectedCity);
    setValue("hpnCity", selectedCity);
    setCitySuggestions([]);
  };

  const handleInputCity = (event) => {
    const input = event.target.value;
    const capitalizedValue = capitalizeWords(input);

    setUserInputCity(capitalizedValue);
    debouncedFetchCitySuggestions(capitalizedValue);
    dispatchQuickQuote("HpnCity", capitalizedValue);
    dispatchMotorQuote("hpnCity", capitalizedValue);
  };

  const handleClickOutside = (event) => {
    if (
      bankWrapperRef.current &&
      !bankWrapperRef.current.contains(event.target) &&
      cityWrapperRef.current &&
      !cityWrapperRef.current.contains(event.target)
    ) {
      setSuggestions([]);
      setCitySuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      debouncedFetchBankSuggestions.cancel();
      debouncedFetchCitySuggestions.cancel();
    };
  }, [debouncedFetchBankSuggestions, debouncedFetchCitySuggestions]);

  const handleSetHpnCity = (val) => {
    dispatchQuickQuote("HpnCity", val.City);
    dispatchMotorQuote("hpnCity", val.City);
  };

  const handleCheckBoxChange = (e) => {
    const { id } = e.target;
    console.log("handleCheckBoxChange", id);
    if (id === "standalonePA") {
      dispatchMotorQuote("reasonForNotOptingCpa", 2);
    } else if (id === "validDL") {
      dispatchMotorQuote("reasonForNotOptingCpa", 1);
    }
    setSelectedPACheckbox(selectedPACheckbox === id ? null : id);
  };

  const handlePrevTPPolicyEndDateChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setPrevTPPolicyEndDate(formattedDate);
      setValue("prevTPPolicyEndDate", formattedDate);
      clearErrors("prevTPPolicyEndDate");
    } else {
      setPrevTPPolicyEndDate(null);
      setValue("prevTPPolicyEndDate", "");
    }
  };

  // Handle manual input change with mask
  const handlePrevTPPolicyEndDateInputChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);

      if (!isNaN(date.getTime())) {
        setPrevTPPolicyEndDate(date);
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setValue("prevTPPolicyEndDate", formattedDate);
        clearErrors("prevTPPolicyEndDate");
      } else {
        setPrevTPPolicyEndDate(null);
        setValue("prevTPPolicyEndDate", "");
      }
    } else {
      setPrevTPPolicyEndDate(null);
      setValue("prevTPPolicyEndDate", "");
    }
  };

  // Handle date change from DatePicker
  const handlePrevPAPolicyEndDateChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setPrevPAPolicyEndDate(formattedDate);
      setValue("prevPAPolicyEndDate", formattedDate);
      clearErrors("prevPAPolicyEndDate");
    } else {
      setPrevPAPolicyEndDate(null);
      setValue("prevPAPolicyEndDate", "");
    }
  };

  // Handle manual input change with mask
  const handlePrevPAPolicyEndDateInputChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);

      if (!isNaN(date.getTime())) {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setPrevPAPolicyEndDate(formattedDate);
        setValue("prevPAPolicyEndDate", formattedDate);
        clearErrors("prevPAPolicyEndDate");
      } else {
        setPrevPAPolicyEndDate(null);
        setValue("prevPAPolicyEndDate", "");
      }
    } else {
      setPrevPAPolicyEndDate(null);
      setValue("prevPAPolicyEndDate", "");
    }
  };

  const handlePucValidUptoInputChange = (event) => {
    const inputValue = event.target.value;
    const isValid = moment(inputValue, "DD/MM/YYYY", true).isValid();
    if (isValid) {
      const formattedDate = moment(inputValue, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      setPucValidUpto(formattedDate);
      setValue("pucValidUpto", formattedDate);
      clearErrors("pucValidUpto");
    } else {
      setPucValidUpto("");
      setValue("pucValidUpto", "");
    }
  };

  const handlePucValidUptoChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setPucValidUpto(formattedDate);
      setValue("pucValidUpto", formattedDate);
      clearErrors("pucValidUpto");
    } else {
      setPucValidUpto(null);
      setValue("pucValidUpto", "");
    }
  };

  return (
    <div className="onwer-personal-details">
      <div className="">
        <form onSubmit={handleSubmit(submitVehicleDetails)} className="w-100">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="onwer-details">
                <div className="did-floating-label-content">
                  <input
                    type="text"
                    className="did-floating-input text-uppercase"
                    {...register("registrationNumber")}
                    placeholder=""
                    maxLength={11}
                    defaultValue={
                      motorRequest?.registrationNumber || motorRequest?.rtoCode
                    }
                    disabled={true}
                  />

                  <label htmlFor="idxx4" className="did-floating-label">
                    Enter Registration Number
                    <span className="text-danger">*</span>
                  </label>
                  <p className="f-error text-danger">
                    {errors?.registrationNumber?.message}
                  </p>
                </div>
              </div>
            </div>

            {selectedPlan?.isAdharRequiredInProposal &&
              motorRequest?.docName !== "adhaar" && (
                <div className="col-md-6 mb-3">
                  <div className="onwer-details">
                    <div className="did-floating-label-content">
                      {
                        <input
                          type="number"
                          className="did-floating-input text-uppercase"
                          {...register("adhaarNumber", {
                            required: "Field is required",
                          })}
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 12))
                          }
                          defaultValue={motorRequest?.adhaarNumber}
                        />
                      }
                      <label htmlFor="idxx4" className="did-floating-label">
                        Enter Aadhar Number
                        <span className="text-danger">*</span>
                      </label>
                      <p className="f-error text-danger">
                        {errors?.adhaarNumber?.message}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            {selectedPlan?.isPanRequiredInProposal &&
              motorRequest?.docName !== "pan" && (
                <div className="col-md-6 mb-3">
                  <div className="onwer-details">
                    <div className="did-floating-label-content">
                      {
                        <input
                          type="text"
                          className="did-floating-input text-uppercase"
                          {...register("panNumber", {
                            required: "Field is required",
                          })}
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 10))
                          }
                          defaultValue={motorRequest?.panNumber}
                        />
                      }
                      <label htmlFor="idxx4" className="did-floating-label">
                        Enter Pan Number
                        <span className="text-danger">*</span>
                      </label>
                      <p className="f-error text-danger">
                        {errors?.panNumber?.message}
                      </p>
                    </div>
                  </div>
                </div>
              )}

            {/* Engine Number */}
            <div className="col-md-6 mb-3">
              <div className="onwer-details">
                <div className="did-floating-label-content">
                  {
                    <input
                      type="text"
                      className="did-floating-input text-uppercase"
                      {...register("engineNumber", {
                        required: "Field is required",
                        // minLength: {
                        //   value: 10,
                        //   message: "Enter a min of 10 characters or number",
                        // },
                      })}
                      onInput={(e) => {
                        e.target.value = e.target.value.slice(0, 25);
                        // e.target.value = e.target.value.replace(
                        //   /[^a-zA-Z0-9-/]/g,
                        //   ""
                        // );
                      }}
                      defaultValue={motorRequest?.engineNumber}
                    />
                  }
                  <label htmlFor="idxx4" className="did-floating-label">
                    Enter Engine Number
                    <span className="text-danger">*</span>
                  </label>
                  <p className="f-error text-danger">
                    {errors?.engineNumber?.message}
                  </p>
                </div>
              </div>
            </div>

            {/* Chassis Number */}
            <div className="col-md-6 mb-3">
              <div className="onwer-details">
                <div className="did-floating-label-content">
                  {
                    <input
                      type="text"
                      className="did-floating-input text-uppercase"
                      placeholder=""
                      {...register("chassisNumber", {
                        required: "Field is required",
                        minLength: {
                          value:
                            motorRequest?.isVehicleNew ||
                            selectedPlan?.insurerId === insurers.Magma.insurerId
                              ? 17
                              : 0,
                          message:
                            motorRequest?.isVehicleNew ||
                            selectedPlan?.insurerId === insurers.Magma.insurerId
                              ? "Enter a minimum of 17 characters or number"
                              : "",
                        },
                        // pattern: {
                        //   value: /^[a-zA-Z0-9]*$/,
                        //   message: "Only alphanumeric characters are allowed",
                        // },
                      })}
                      onInput={(e) => {
                        const isMagma =
                          selectedPlan?.insurerId === insurers.Magma.insurerId
                            ? true
                            : false;
                        console.log("isMagma", isMagma);
                        const maxLength =
                          isMagma && motorRequest?.isVehicleNew ? 25 : 25;
                        e.target.value = e.target.value.slice(0, maxLength);
                        // e.target.value = isMagma
                        //   ? e.target.value
                        //   : e.target.value.replace(/[^a-zA-Z0-9/-]/g, "");
                      }}
                      defaultValue={motorRequest?.chassisNumber}
                    />
                  }
                  <label htmlFor="idxx4" className="did-floating-label">
                    Enter Chassis Number
                    <span className="text-danger">*</span>
                  </label>
                  <p className="f-error text-danger">
                    {errors?.chassisNumber?.message}
                  </p>
                </div>
              </div>
            </div>

            {(motorRequest?.businessType === POLICY_STATUS.EXPIREDWITHIN90 ||
              motorRequest?.businessType === POLICY_STATUS.NOTEXPIRED) && (
              <div className="col-md-6 mb-3">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <>
                      <input
                        type="text"
                        toUpperCase
                        className="did-floating-input previousPolicyNumber text-uppercase"
                        {...register("prevPolicyNumber", {
                          required: "Field is required",
                        })}
                        placeholder=""
                        maxLength={30}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^a-zA-Z0-9]/g,
                            ""
                          );
                        }}
                        defaultValue={motorRequest?.prevPolicyNumber}
                      />
                    </>

                    <label htmlFor="idxx4" className="did-floating-label">
                      {motorRequest?.newPolicyType === POLICY_TYPE.ODONLY &&
                      motorRequest?.prevPolicyType !==
                        POLICY_TYPE.PREVIOUSBUNDLED
                        ? "Enter Previous SAOD Policy Number"
                        : `Enter Previous Policy Number`}
                      <span className="text-danger">*</span>
                    </label>
                    <p className="f-error text-danger">
                      {errors?.prevPolicyNumber?.message}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {motorRequest?.savedDataForVahanDetails?.data?.prevInsurerCode ==
              "14" && (
              <div className="col-md-6 mb-3">
                <div className="did-floating-label-content">
                  <div className="tp-policy">
                    <select
                      name={"prevInsurerCode"}
                      className="form-select"
                      {...register("insurerCode", {
                        required: "Field is required",
                      })}
                      placeholder="Previous Insurerr"
                    >
                      <option value="">Select</option>
                      {insurerData.map((item, index) => (
                        <option
                          key={index}
                          value={item.insurerId}
                          selected={
                            parseInt(motorRequest?.prevInsurerCode) ===
                            item?.insurerId
                              ? true
                              : false
                          }
                        >
                          {item.option}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="idxx4" className="did-floating-label">
                      Previous Insurer
                      <span className="text-danger">*</span>
                    </label>
                    <p className="f-error text-danger">
                      {errors?.insurerCode?.message}
                    </p>
                  </div>
                </div>
              </div>
            )}

            {motorRequest?.prevPolicyType !== POLICY_TYPE.PREVIOUSBUNDLED &&
              motorRequest?.newPolicyType === POLICY_TYPE?.ODONLY && (
                <>
                  <div className="col-md-6 mb-3">
                    <div className="did-floating-label-content ">
                      <input
                        type="text"
                        className="did-floating-input text-uppercase"
                        {...register("prevTPPolicyNumber", {
                          required: "Field is required",
                        })}
                        placeholder=""
                        maxLength={30}
                        defaultValue={motorRequest?.prevTPPolicyNumber}
                      />
                      <label htmlFor="idxx4" className="did-floating-label">
                        Previous TP policy number
                        <span className="text-danger">*</span>
                      </label>
                      <p className="f-error text-danger">
                        {errors?.prevTPPolicyNumber?.message}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="did-floating-label-content">
                      <div className="tp-policy">
                        <select
                          name={"prevTPInsurerCode"}
                          className="form-select"
                          {...register("prevTPInsurerCode", {
                            required: "Field is required",
                          })}
                          placeholder="Previous TP Policy Holder"
                        >
                          <option value="">Select</option>
                          {insurerData.map((item, index) => (
                            <option
                              key={index}
                              value={item.insurerId}
                              selected={
                                parseInt(motorRequest?.prevTPInsurerCode) ===
                                item?.insurerId
                                  ? true
                                  : false
                              }
                            >
                              {item.option}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="idxx4" className="did-floating-label">
                          Previous TP Policy Holder
                          <span className="text-danger">*</span>
                        </label>
                        <p className="f-error text-danger">
                          {errors?.prevTPInsurerCode?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}

            {motorRequest?.newPolicyType === POLICY_TYPE?.ODONLY && (
              <div className="col-md-6 mb-3">
                <div className="did-floating-label-content">
                  <DatePicker
                    className="did-floating-input"
                    {...register("prevTPPolicyEndDate", {
                      required: "Field is required",
                    })}
                    selected={prevTPPolicyEndDate}
                    onChange={handlePrevTPPolicyEndDateChange}
                    customInput={
                      <ReactInputMask
                        mask="99/99/9999"
                        placeholder="DD/MM/YYYY"
                        value={
                          prevTPPolicyEndDate
                            ? moment(prevTPPolicyEndDate).format("DD/MM/YYYY")
                            : ""
                        }
                        onChange={handlePrevTPPolicyEndDateInputChange}
                        alwaysShowMask={false}
                      />
                    }
                    minDate={new Date(motorRequest.registrationDate)}
                    maxDate={moment(motorRequest.registrationDate, "YYYY-MM-DD")
                      .add(5.1, "years")
                      .toDate()}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Select Previous TP Policy End Date"
                  />
                  <label
                    htmlFor="prevTPPolicyEndDate"
                    className="did-floating-label"
                  >
                    Previous TP Policy End Date
                    <span className="text-danger">*</span>
                  </label>
                  <p className="f-error text-danger">
                    {errors?.prevTPPolicyEndDate?.message}
                  </p>
                </div>
              </div>
            )}

            {!selectedPlan?.PersonalAccident &&
              motorRequest?.newPolicyType !== POLICY_TYPE.ODONLY &&
              motorRequest?.customerType !== CUSTOMER_TYPE.ORGANISATION && (
                <>
                  <div className="col-md-6 mb-3">
                    <div className="did-floating-label-content">
                      <div className="tp-policy">
                        <select
                          name={"reasonForNotOptingCpa"}
                          className="form-select"
                          {...register("reasonForNotOptingCpa", {
                            required: "Field is required",
                          })}
                          placeholder="Reasons for not Opting PA"
                          // value={motorRequest?.reasonForNotOptingCpa || ""}
                        >
                          <option value="">Select</option>
                          <option value={1}>{reasonForNotOptingCpa[1]}</option>
                          <option value={2}>{reasonForNotOptingCpa[2]}</option>
                        </select>
                        <label htmlFor="idxx4" className="did-floating-label">
                          Reasons for not opting PA?
                          <span className="text-danger">*</span>
                        </label>
                        <p className="f-error text-danger">
                          {errors?.reasonForNotOptingCpa?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="enter-all-check mb-4">
                    <div className="enter-check">
                      <input
                        type="checkbox"
                        id="validDL"
                        checked={selectedPACheckbox === "validDL"}
                        onChange={handleCheckBoxChange}
                      />
                      <label htmlFor="validDL">
                        Owner Driving does not hold valid Driving License
                      </label>
                    </div> */}
                  {/*----- No Input -----*/}
                  {/* <div className="enter-check">
                      <input
                        type="checkbox"
                        id="standalonePA"
                        checked={selectedPACheckbox === "standalonePA"}
                        onChange={handleCheckBoxChange}
                      />
                      <label htmlFor="standalonePA">
                        {`Insured has standalone PA cover >= 15 lakhs`}
                      </label>
                    </div>
                  </div> */}

                  {watch("reasonForNotOptingCpa") === "2" &&
                    selectedPlan?.insurerId === insurers.Future.insurerId && (
                      // selectedPlan?.Api_name ===
                      //   "Future Generali India Insurance Company Limited" &&
                      //   !selectedPlan?.PersonalAccident &&
                      <>
                        <div className="col-md-6 mb-3">
                          <div className="did-floating-label-content ">
                            <input
                              type="text"
                              className="did-floating-input text-uppercase"
                              {...register("prevPAPolicyNumber", {
                                required: "Field is required",
                              })}
                              placeholder=""
                              maxLength={30}
                              defaultValue={motorRequest?.prevPAPolicyNumber}
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Previous PA policy number
                              <span className="text-danger">*</span>
                            </label>
                            <p className="f-error text-danger">
                              {errors?.prevPAPolicyNumber?.message}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="did-floating-label-content ">
                            <input
                              type="text"
                              className="did-floating-input text-uppercase"
                              {...register("prevPASumInsured", {
                                required: "Field is required",
                              })}
                              placeholder=""
                              maxLength={10}
                              min={1500000}
                              defaultValue={motorRequest?.prevPASumInsured}
                            />
                            <label
                              htmlFor="idxx4"
                              className="did-floating-label"
                            >
                              Previous PA Sum Insured
                              <span className="text-danger">*</span>
                            </label>
                            <p className="f-error text-danger">
                              {errors?.prevPASumInsured?.message}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="did-floating-label-content">
                            <div className="tp-policy">
                              <select
                                name={"prevPAInsurerCode"}
                                className="form-select"
                                {...register("prevPAInsurerCode", {
                                  required: "Field is required",
                                })}
                                placeholder="Previous TP Policy Holder"
                              >
                                <option value="">Select</option>
                                {insurerData.map((item, index) => (
                                  <option
                                    key={index}
                                    value={item.insurerId}
                                    selected={
                                      parseInt(
                                        motorRequest?.prevTPInsurerCode
                                      ) === item?.insurerId
                                        ? true
                                        : false
                                    }
                                  >
                                    {item.option}
                                  </option>
                                ))}
                              </select>
                              <label
                                htmlFor="idxx4"
                                className="did-floating-label"
                              >
                                Previous PA Insurer
                                <span className="text-danger">*</span>
                              </label>
                              <p className="f-error text-danger">
                                {errors?.prevPAInsurerCode?.message}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="did-floating-label-content">
                            <DatePicker
                              className="did-floating-input"
                              {...register("prevPAPolicyEndDate", {
                                required: "Field is required",
                              })}
                              selected={prevPAPolicyEndDate}
                              onChange={handlePrevPAPolicyEndDateChange}
                              customInput={
                                <ReactInputMask
                                  mask="99/99/9999"
                                  placeholder="DD/MM/YYYY"
                                  value={
                                    prevPAPolicyEndDate
                                      ? moment(prevPAPolicyEndDate).format(
                                          "DD/MM/YYYY"
                                        )
                                      : ""
                                  }
                                  onChange={
                                    handlePrevPAPolicyEndDateInputChange
                                  }
                                  alwaysShowMask={false}
                                />
                              }
                              minDate={new Date(motorRequest.registrationDate)}
                              maxDate={moment(
                                motorRequest.registrationDate,
                                "YYYY-MM-DD"
                              )
                                .add(5.1, "years")
                                .toDate()}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="Select Previous Policy PA End Date"
                            />
                            <label
                              htmlFor="prevPAPolicyEndDate"
                              className="did-floating-label"
                            >
                              Previous Policy PA End Date
                              <span className="text-danger">*</span>
                            </label>
                            <p className="f-error text-danger">
                              {errors?.prevPAPolicyEndDate?.message}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                </>
              )}

            {/*----- Yes Input -----*/}
            <div className="enter-all-check">
              <div className="enter-check">
                <input
                  type="checkbox"
                  // className=""
                  id="formyes"
                  checked={Hpn}
                  onChange={(e) => changeHpnState(e)}
                />
                <label
                  htmlFor="formyes"
                  // className="ms-lg-0"
                  onclick="togglediv2()"
                >
                  Hypothecation
                </label>
              </div>
              {/*----- No Input -----*/}
              {(selectedPlan?.Api_name === insurers.Future.name ||
                selectedPlan?.Api_name === insurers.Magma.name) && (
                <div className="enter-check">
                  <input
                    type="checkbox"
                    // className=""
                    id="formno"
                    onChange={(e) => changePUCState(e)}
                    // checked={isValidPUC}
                    defaultChecked={
                      selectedPlan?.Api_name === insurers.Future.name ||
                      selectedPlan?.Api_name === insurers.Magma.name
                        ? true
                        : false
                    }
                    disabled
                  />
                  <label htmlFor="formno" onclick="togglediv1()">
                    Is valid PUC available?
                  </label>
                </div>
              )}
            </div>
            <div className="mt-5 mb-4">
              {Hpn && (
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div
                      className="did-floating-label-content"
                      ref={bankWrapperRef}
                    >
                      <input
                        id="idxx4"
                        {...register("hpnBank", {
                          required: "Field is required",
                        })}
                        value={userInputBank}
                        onChange={handleInputBank}
                        onBlur={handleBankInputBlur}
                        onInput={() => setSelectedBank("")}
                        className="did-floating-input previousPolicyNumber"
                        placeholder="Type or Select Bank Name"
                        defaultValue={motorRequest?.hpnBank}
                      />
                      <label htmlFor="idxx4" className="did-floating-label">
                        Select finance bank
                      </label>
                      {suggestions.length > 0 && (
                        <div
                          style={{
                            position: "absolute",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            padding: "1rem",
                            backgroundColor: "white",
                            zIndex: 1000,
                            width: "100%",
                            cursor: "pointer",
                          }}
                        >
                          <List
                            height={200}
                            itemCount={suggestions.length}
                            itemSize={35}
                            width="100%"
                          >
                            {({ index, style }) => (
                              <div
                                style={style}
                                key={index}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleBankSelection(suggestions[index]);
                                }}
                                className="suggestion-item"
                              >
                                {suggestions[index].value}
                              </div>
                            )}
                          </List>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className="did-floating-label-content"
                      ref={cityWrapperRef}
                    >
                      <input
                        id="idxx4"
                        {...register("hpnCity", {
                          required: "Field is required",
                        })}
                        value={userInputCity}
                        onChange={handleInputCity}
                        onInput={() => setSelectedCity("")}
                        className="did-floating-input previousPolicyNumber"
                        placeholder="Type or Select Bank Name"
                        defaultValue={motorRequest?.hpnCity}
                      />
                      <label htmlFor="idxx4" className="did-floating-label">
                        Select finance city
                      </label>
                      {citySuggestions.length > 0 && (
                        <div
                          style={{
                            position: "absolute",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            padding: "1rem",
                            backgroundColor: "white",
                            zIndex: 1000,
                            width: "100%",
                          }}
                        >
                          <List
                            height={200}
                            itemCount={citySuggestions.length}
                            itemSize={35}
                            width="100%"
                          >
                            {({ index, style }) => (
                              <div
                                style={style}
                                key={index}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCitySelection(
                                    citySuggestions[index].City
                                  );
                                }}
                                className="suggestion-item"
                              >
                                {`${citySuggestions[index].City}`}
                              </div>
                            )}
                          </List>
                        </div>
                      )}
                    </div>
                    {/* <div className="did-floating-label-content">
                      <ReactSelect
                        options={cityData}
                        onChange={(val) => handleSetHpnCity(val)}
                        placeholder=""
                        defaultValue={motorRequest?.hpnCity}
                      />
                      <label htmlFor="idxx4" className="did-floating-label">
                        Select finance city
                      </label>
                    </div> */}
                  </div>
                </div>
              )}
              {(selectedPlan?.Api_name === insurers.Future.name ||
                selectedPlan?.Api_name === insurers.Magma.name) &&
                isValidPUC && (
                  <div className="row">
                    <div className="col-md-6">
                      <div className="did-floating-label-content">
                        <input
                          type="text"
                          className="did-floating-input"
                          {...register(
                            "pucNumber",
                            selectedPlan?.Api_name === insurers.Future.name && {
                              required: "Field is required",
                            }
                          )}
                          placeholder=""
                          defaultValue={motorRequest?.pucNumber}
                        />
                        <label htmlFor="idxx4" className="did-floating-label">
                          Enter PUC Number
                        </label>
                      </div>
                      <p className="f-error text-danger">
                        {errors?.pucNumber?.message}
                      </p>
                    </div>

                    <div className="col-md-6">
                      <div className="did-floating-label-content">
                        <DatePicker
                          className="did-floating-input"
                          {...register("pucValidUpto", {
                            required: "Field is required",
                          })}
                          selected={
                            pucValidUpto ? moment(pucValidUpto).toDate() : null
                          }
                          onChange={handlePucValidUptoChange}
                          customInput={
                            <ReactInputMask
                              mask="99/99/9999"
                              placeholder="DD/MM/YYYY"
                              value={
                                pucValidUpto
                                  ? moment(pucValidUpto).format("DD/MM/YYYY")
                                  : ""
                              }
                              onChange={handlePucValidUptoInputChange}
                              alwaysShowMask={false}
                            />
                          }
                          minDate={moment().toDate()}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Select PUC valid upto"
                        />
                        <label
                          htmlFor="pucValidUpto"
                          className="did-floating-label"
                        >
                          PUC valid upto
                        </label>
                        <p className="f-error text-danger">
                          {errors?.pucValidUpto?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div>
            <div className="next-btn-btn">
              <button
                className="proceedBtn1"
                onClick={() => handlePreviousStep()}
              >
                Previous
              </button>

              <button
                type="submit"
                className="proceedBtn1"
                // onClick={() => handleNextStep()}
              >
                {vehicleDetailsSpinner ? (
                  <div class="spinner-border text-light" role="status"></div>
                ) : (
                  "Proceed"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <PremiumMismatchModal
        show={premiumMismatchModal}
        setModal={setPremiumMismatchModal}
        error={submitProposalError}
        updatedQuote={updatedQuote}
        setVehicleDetailsSpinner={setVehicleDetailsSpinner}
        vehicleDetailsSpinner={vehicleDetailsSpinner}
        kycMode={kycMode}
        setKycMode={setKycMode}
        setCurrentStep={setCurrentStep}
      />
    </div>
  );
};

export default VehicleDetails;
